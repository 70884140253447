html {
  scroll-behavior: smooth; }

.row {
  margin-left: -25px;
  margin-right: -25px; }

.mb-50 {
  margin-bottom: 50px; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto {
  padding-left: 25px;
  padding-right: 25px; }

.slider.dim:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1; }

.slider.dim > div {
  z-index: 2; }

@media (min-width: 1200px) {
  .container {
    max-width: 1440px; } }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.ptb-120 {
  padding: 120px 0; }

.pt-120 {
  padding-top: 120px; }

.pb-120 {
  padding-bottom: 120px; }

@media (min-width: 720px) {
  .pb-md-120 {
    padding-bottom: 120px; }
  .pt-md-120 {
    padding-top: 120px; } }

@media (min-width: 540px) {
  .pb-sm-120 {
    padding-bottom: 60px; }
  .pt-sm-120 {
    padding-top: 60px; } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.4); }
  100% {
    transform: scale(1); } }

@keyframes up-dawn {
  0% {
    transform: translateY(-20px); }
  50% {
    transform: translateY(0); }
  100% {
    transform: translateY(-20px); } }

body {
  font-family: 'Raleway'; }

.h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 50px;
  font-family: "Montserrat", sans-serif; }

.h2-white {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 50px;
  font-family: "Montserrat", sans-serif; }

.h3 {
  font-size: 30px;
  font-weight: 600;
  color: #263b5e;
  margin: 0;
  font-family: "Montserrat", sans-serif; }

.h4 {
  font-size: 20px;
  font-weight: 500;
  color: #263b5e;
  margin: 0;
  font-family: "Montserrat", sans-serif; }

.p-1 {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif; }

.p-2 {
  font-size: 22px;
  font-weight: 300;
  color: #000000;
  line-height: 28px;
  margin: 0;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif; }

.p-3 {
  font-size: 15px;
  font-weight: 300;
  color: #000000;
  line-height: 28px;
  margin: 0;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif; }

.p-white {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  line-height: 28px;
  margin: 0;
  padding: 0 !important;
  font-family: "Montserrat", sans-serif; }

dl, ol, ul {
  font-family: "Montserrat", sans-serif; }

.mb-7 {
  margin-bottom: 7px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.font-mon {
  font-family: "Montserrat", sans-serif; }

.f-22 {
  font-size: 22px; }

.f-30 {
  font-size: 30px; }

.f-46 {
  font-size: 46px; }

p {
  font-size: 22px; }

.how-work {
  background-color: #F8F8F8; }
  .how-work .box {
    background-color: #ffffff;
    height: 100%; }
    .how-work .box.archive {
      cursor: not-allowed;
      position: relative;
      background-color: transparent; }
      .how-work .box.archive p, .how-work .box.archive a, .how-work .box.archive h2, .how-work .box.archive h3, .how-work .box.archive h4, .how-work .box.archive img {
        opacity: 0.5; }
    .how-work .box .content {
      padding: 25px 20px 32px 20px;
      box-sizing: border-box;
      height: 100%; }
    .how-work .box h4 {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      line-height: 108.7%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-weight: 400;
      color: #000000; }
    .how-work .box h3 {
      font-weight: 700;
      font-size: 27px;
      line-height: 108.7%;
      letter-spacing: -0.04em;
      font-family: "Montserrat", sans-serif; }
      .how-work .box h3 a {
        color: #000000; }
        .how-work .box h3 a:hover {
          text-decoration: none; }
    .how-work .box .date {
      font-weight: 600;
      font-size: 14px;
      line-height: 147.9%;
      letter-spacing: -0.04em;
      border-top: 1px solid #DDDDDD;
      border-bottom: 1px solid #DDDDDD;
      padding: 14px 0;
      font-family: "Montserrat", sans-serif; }
      .how-work .box .date img {
        margin-right: 12px; }
    .how-work .box .excerpt {
      font-size: 18px;
      line-height: 147.9%;
      letter-spacing: -0.04em; }
  .how-work .large-box {
    position: relative;
    background: #F8F8F8;
    color: #ffffff; }
    .how-work .large-box h2, .how-work .large-box h3, .how-work .large-box h4, .how-work .large-box h3 a, .how-work .large-box p {
      color: #ffffff; }
    .how-work .large-box:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000000 0%, #000000 25.68%, #2E2E2E 100%);
      z-index: 1; }
    .how-work .large-box .large-box-image {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      width: 100%; }
    .how-work .large-box .content {
      padding: 3rem;
      position: relative;
      z-index: 3; }
    .how-work .large-box p {
      line-height: 147.9%;
      letter-spacing: -0.04em; }
    .how-work .large-box h4 {
      font-weight: 300;
      font-size: 30px;
      line-height: 108.7%;
      letter-spacing: 0.06em; }
    .how-work .large-box h3 {
      font-weight: bold;
      font-size: 46px;
      line-height: 108.7%;
      letter-spacing: -0.04em; }
      .how-work .large-box h3 a {
        color: #DD0935; }

.small-container {
  max-width: 850px;
  padding: 0 15px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto; }

.heading .small,
.heading .large {
  font-family: "Montserrat", sans-serif; }

.heading .small {
  font-weight: 300;
  font-size: 30px;
  line-height: 108.7%;
  letter-spacing: 0.06em; }

.heading .large {
  font-weight: bold;
  font-size: 46px;
  line-height: 108.7%;
  letter-spacing: -0.04em; }

.heading .color-red {
  color: #DD0935; }

.paragraph {
  font-weight: normal;
  font-size: 22px;
  line-height: 147.9%;
  letter-spacing: -0.04em;
  font-family: "Montserrat", sans-serif; }

.color-white {
  color: #ffffff; }
  .color-white h2, .color-white h3, .color-white h4, .color-white p, .color-white a, .color-white li {
    color: #ffffff; }

.check {
  background-image: linear-gradient(to right bottom, #01c9db, #039bb2);
  padding: 120px;
  position: relative; }
  .check .rocket-1 {
    position: absolute;
    right: 0;
    bottom: 0; }
  .check .rocket-2 {
    position: absolute;
    top: 0;
    left: 0; }

.offers {
  background-color: #F8F8F8; }
  .offers .row > div {
    margin-top: 160px; }
  .offers .box {
    position: relative;
    padding: 120px 20px 20px 20px;
    background-color: #ffffff;
    transition: all .3s ease-in-out;
    /* height: 100%; */ }
    .offers .box .team {
      position: absolute;
      left: 50%;
      top: -90px;
      transform: translateX(-50%);
      width: 100%;
      max-width: 180px;
      margin-left: auto;
      margin-right: auto;
      z-index: 1; }
    .offers .box .team-2 {
      position: relative;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 100%;
      max-width: 180px;
      margin-left: auto;
      margin-right: auto; }
    .offers .box .text {
      height: 100%; }
      .offers .box .text .small,
      .offers .box .text .large {
        font-family: "Montserrat", sans-serif; }
      .offers .box .text .small {
        font-weight: normal;
        font-size: 14px;
        line-height: 108.7%;
        letter-spacing: 0.03em;
        font-family: "Montserrat", sans-serif; }
      .offers .box .text .large {
        font-weight: bold;
        font-size: 27px;
        line-height: 108.7%;
        letter-spacing: -0.04em;
        font-family: "Montserrat", sans-serif; }
    .offers .box .icon img {
      width: 40px; }
  .offers .team-info {
    height: 100% !important; }
  .offers .social {
    margin-top: 30px;
    border-top: 1px solid #DDDDDD;
    padding-top: 20px; }
    .offers .social div,
    .offers .social a {
      color: #000000;
      font-weight: 600;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      line-height: 147.9%;
      letter-spacing: -0.04em; }
      .offers .social div:hover,
      .offers .social a:hover {
        text-decoration: none; }
    .offers .social img {
      margin-right: 16px;
      max-width: 23px; }

.counter {
  background-color: #F8F8F8; }
  .counter .content .circle {
    max-width: 135px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .counter .content .circle .text {
      width: 100%; }
    .counter .content .circle h3 {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 66px;
      line-height: 108.7%;
      text-align: center;
      letter-spacing: -0.04em;
      margin: 0;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 2px solid #000000; }
    .counter .content .circle .num {
      font-family: "Montserrat", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 108.7%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase; }
    .counter .content .circle .color-1 {
      color: #DD0935; }
    .counter .content .circle .color-2 {
      color: #f5a315; }
    .counter .content .circle .color-3 {
      color: #9cbb2f; }
    .counter .content .circle .color-4 {
      color: #009bc2; }

.customers {
  background-color: #000000;
  padding: 120px 0 150px 0; }
  .customers .carousel {
    position: relative;
    z-index: 2;
    width: calc(100% + 340px);
    margin-left: -170px; }
  .customers .carousel-control-next, .customers .carousel-control-prev {
    bottom: -60px;
    top: initial;
    margin-top: 0; }
  .customers .carousel-control-next {
    right: initial;
    left: 220px; }
  .customers .carousel-control-prev {
    transform: rotate(180deg);
    left: 155px; }
  .customers .parteners {
    display: none; }
  .customers .quote {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 40%; }
  .customers .testimonials .carousel-inner {
    position: relative;
    z-index: 2;
    padding: 30px;
    width: 100%;
    border-radius: 5px; }
    .customers .testimonials .carousel-inner img {
      position: absolute;
      left: 0;
      top: -20px;
      border-radius: 50%; }
    .customers .testimonials .carousel-inner .small {
      font-size: 14px;
      text-transform: uppercase; }
    .customers .testimonials .carousel-inner .large {
      font-size: 27px;
      margin-bottom: 40px; }
  .customers .testimonials .carousel-item {
    padding: 0 150px; }
  .customers .testimonials .carousel-item h3 {
    font-weight: 500;
    margin: 20px 0 6px; }
  .customers .testimonials .carousel-item h4 {
    font-weight: 300;
    margin: 0;
    padding: 0; }
  .customers .testimonials .carousel-item p {
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    margin-top: 15px; }
  .customers .testimonials .carousel-item .line {
    background: #eff0f3;
    height: 1px;
    width: 100%; }
  .customers .testimonials .carousel-item .tes {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    font-style: italic; }
    .customers .testimonials .carousel-item .tes span {
      color: #000000;
      font-size: 12px;
      font-weight: 300;
      font-style: normal; }
  .customers .testimonials .carousel-indicators {
    display: none; }
  .customers .testimonials .control {
    cursor: pointer;
    margin-top: 235px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 20px;
    opacity: 1 !important;
    margin: 20px;
    margin-top: 215px; }

.ready {
  background-color: #DD0935; }

a.btn-blue {
  background-image: linear-gradient(to right bottom, #01c9db, #039bb2);
  padding: 12px 45px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
  box-shadow: 0px 7px 20px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.4s ease-in-out; }
  a.btn-blue:hover {
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-5px); }
    a.btn-blue:hover::after {
      transform: scale(1.5);
      opacity: 0; }
  a.btn-blue::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 35px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(to right bottom, #01c9db, #039bb2); }

div.btn {
  padding: 0; }

a.btn-white {
  background: #ffffff;
  padding: 12px 45px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
  box-shadow: 0px 7px 20px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.4s ease-in-out; }
  a.btn-white:hover {
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-5px); }
    a.btn-white:hover::after {
      transform: scale(1.5);
      opacity: 0; }
  a.btn-white::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 35px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s ease-in-out;
    background: #ffffff; }

a.btn-white-line {
  background: transparent;
  padding: 15px 22px;
  color: #ffffff;
  font-size: 14px;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  border: 1px solid #ffffff;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  a.btn-white-line:hover {
    color: #000000;
    background-color: #ffffff; }

a.btn-black-line {
  background: transparent;
  padding: 15px 22px;
  color: #000000;
  font-size: 14px;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  border: 1px solid #000000;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  a.btn-black-line:hover {
    color: #ffffff;
    background-color: #000000; }

button.btn-red,
a.btn-red {
  background: transparent;
  padding: 15px 19px;
  color: #DD0935;
  border: 1px solid #DD0935;
  font-size: 14px;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  button.btn-red:hover,
  a.btn-red:hover {
    background-color: #DD0935;
    color: #ffffff; }

a.btn-red-white {
  background: #ffffff;
  padding: 15px 19px;
  color: #DD0935;
  border: 1px solid #DD0935;
  font-size: 14px;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  a.btn-red-white:hover {
    background-color: #DD0935;
    color: #ffffff; }

a.btn-red-full {
  background: #DD0935;
  padding: 15px 19px;
  color: #ffffff;
  border: 1px solid #DD0935;
  font-size: 14px;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  a.btn-red-full:hover {
    background-color: #ffffff;
    color: #DD0935; }

a.btn-black {
  background: #ffffff;
  padding: 15px 19px;
  color: #000000;
  font-size: 14px;
  border: 1px solid #ffffff;
  line-height: 108.7%;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 100px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }
  a.btn-black:hover {
    text-decoration: underline; }

@media (max-width: 991px) {
  .btn {
    padding: 10px 19px !important; } }

.shape-1 {
  position: absolute;
  width: 25px;
  top: 50%;
  right: 15%;
  animation: pulse 3s  infinite ease-in-out; }

.shape-2 {
  position: absolute;
  width: 30px;
  top: 20%;
  right: 25%;
  animation: up-dawn 5s  infinite ease-in-out; }

.shape-3 {
  position: absolute;
  width: 15px;
  bottom: 10%;
  right: 20%;
  animation: up-dawn 5s  infinite ease-in-out 1s; }

.shape-4 {
  position: absolute;
  width: 25px;
  top: 20%;
  left: 20%;
  animation: pulse 3s  infinite ease-in-out 1.5s; }

.shape-5 {
  position: absolute;
  width: 20px;
  top: 30%;
  left: 50%;
  animation: up-dawn 5s  infinite ease-in-out 1.7s; }

.shape-6 {
  position: absolute;
  width: 15px;
  bottom: 30%;
  left: 40%;
  animation: up-dawn 5s  infinite ease-in-out 2.3s; }

.shape-7 {
  position: absolute;
  width: 25px;
  bottom: 15%;
  left: 20%;
  animation: pulse 3s  infinite ease-in-out 1s; }

.shape-8 {
  position: absolute;
  width: 25px;
  bottom: 10%;
  left: 60%;
  animation: up-dawn 5s  infinite ease-in-out 3s; }

form.form-inline .form-group input.form-control {
  border: 0;
  width: 500px;
  height: 60px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: 300;
  color: #000000; }

.nf-field-label label {
  font-weight: 400;
  font-family: "Montserrat", sans-serif; }

.nf-form-content button, .nf-form-content input[type=button], .nf-form-content input[type=submit] {
  font-family: "Montserrat", sans-serif;
  background-color: #dd0935 !important;
  border: 1px solid #dd0935;
  color: #fff !important;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 5px 20px; }

.nf-form-content .list-select-wrap .nf-field-element > div, .nf-form-content input:not([type=button]), .nf-form-content textarea {
  font-family: "Montserrat", sans-serif; }

.banner-with-content {
  position: relative;
  padding: 70px 0;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Montserrat", sans-serif; }
  .banner-with-content.bg-dark {
    background: linear-gradient(180deg, #000000 0%, #000000 25.68%, #2E2E2E 100%); }
  .banner-with-content.bg-black:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }
  .banner-with-content .title-large {
    font-size: 66px; }
  .banner-with-content .title-normal {
    font-size: 40px;
    font-family: "Montserrat", sans-serif; }
  .banner-with-content .bg-image {
    display: none;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%; }
  .banner-with-content h2 {
    font-family: "Montserrat", sans-serif; }
  .banner-with-content.color-white p, .banner-with-content.color-white h2 {
    color: #ffffff; }
  .banner-with-content.color-black p, .banner-with-content.color-black h2 {
    color: #000000; }
  .banner-with-content .content {
    position: relative;
    z-index: 2;
    max-width: 850px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: auto; }
  .banner-with-content .form-inline {
    background-color: #000000;
    height: 67px;
    border-radius: 34px;
    padding: 0 10px;
    box-sizing: border-box; }
    .banner-with-content .form-inline > div {
      margin-bottom: 0 !important; }
    .banner-with-content .form-inline input {
      padding-left: 28px;
      background-color: transparent;
      font-size: 22px;
      color: #ffffff !important; }
    .banner-with-content .form-inline .form-control {
      width: 100% !important; }

@media (max-width: 991px) {
  .banner-with-content {
    padding: 100px 0; }
  .banner-with-content .title-normal {
    font-size: 30px; }
  .banner-with-content .title-large {
    font-size: 50px; } }

@media (max-width: 576px) {
  .banner-with-content {
    padding: 50px 0; }
  .banner-with-content .title-normal {
    font-size: 20px; }
  .banner-with-content .title-large {
    font-size: 30px; } }

.navbar {
  background: rgba(0, 0, 0, 0.7); }
  .navbar .nav-item {
    margin: 0;
    padding: 25px 0; }
    .navbar .nav-item .nav-link {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 108.7%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffffff;
      margin-right: 30px; }
      .navbar .nav-item .nav-link:hover {
        color: #DD0935; }
  .navbar .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar .dropdown .dropdown-menu {
    border: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 0; }
  .navbar .dropdown .dropdown-menu a {
    padding: 15px 5px 15px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    transition: all 0.2s ease-in-out; }
    .navbar .dropdown .dropdown-menu a:not(:last-child) {
      border-bottom: 1px solid #f6f6f6; }
    .navbar .dropdown .dropdown-menu a:hover {
      color: #ffffff;
      background-color: #01c9db;
      border: none; }
      .navbar .dropdown .dropdown-menu a:hover:not(:last-child) {
        border-bottom: 1px solid #01c9db; }
  .navbar .dropdown-toggle:after {
    content: none; }

.slider {
  background-color: #000000;
  height: 740px;
  position: relative; }
  .slider .large {
    font-size: 66px; }
  .slider .container {
    position: relative; }
  .slider .content {
    position: relative;
    z-index: 2; }
  .slider .heart {
    position: absolute;
    right: 0;
    top: -100px;
    width: 1100px;
    z-index: 1; }
  .slider .mobile-swipe {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%); }
  .slider:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1; }

@media (max-width: 1366px) {
  .slider .heart {
    width: 950px; } }

@media (max-width: 991px) {
  .slider:after {
    background: rgba(0, 0, 0, 0.7); }
  .slider .container,
  .mobile-swipe {
    z-index: 2; }
  .slider .mobile-swipe {
    display: block; }
  div.slider {
    height: 740px !important; }
  .slider .heart {
    top: initial;
    bottom: 0; } }

@media (max-width: 576px) {
  .slider .heading .small {
    font-size: 20px; }
  .slider .heading .large {
    font-size: 46px; }
  .heading .small {
    font-size: 20px; }
  .heading .large {
    font-size: 33px; } }

.footer {
  background-color: #000000; }
  .footer p, .footer a, .footer li {
    font-style: normal;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 147.9%;
    letter-spacing: -0.04em; }
  .footer .who {
    padding-right: 30px; }
  .footer .links h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
  .footer .links ul {
    margin: 0;
    padding: 0;
    max-width: 280px;
    padding-left: 40px; }
    .footer .links ul li {
      list-style: none; }
      .footer .links ul li a {
        position: relative;
        transition: all .2s ease-in-out;
        /* padding-left: 40px; */ }
        .footer .links ul li a:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxOCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMyAxTDE3IDVMMTMgOSIgc3Ryb2tlPSIjREQwOTM1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjxwYXRoIGQ9Ik0xNyA1SDEiIHN0cm9rZT0iI0REMDkzNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==);
          background-position: left;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          margin-left: -40px;
          top: 0;
          /* top: -4px; */ }
        .footer .links ul li a:hover {
          color: #DD0935; }
      .footer .links ul li:not(:last-child) {
        margin-bottom: 10px; }
  .footer .links p a {
    color: #DD0935; }
  .footer .social a {
    margin-right: 15px; }
  .footer .social .fa {
    color: #DD0935; }
  .footer .bottom {
    background: #6a7695;
    padding: 10px 0; }
    .footer .bottom span {
      font-size: 15px;
      color: #6a7695;
      display: inline-block;
      margin: 0;
      padding: 0; }

.copyright {
  background-color: #000000; }
  .copyright .container {
    border-top: 1px solid #B8B8B8; }
  .copyright span {
    color: #ffffff;
    font-family: "Montserrat", sans-serif; }

.bottom {
  background: #e3eef0;
  padding: 20px 0; }
  .bottom span {
    font-size: 15px;
    color: #6a7695;
    display: inline-block;
    margin: 0;
    padding: 0; }

.curse-container {
  background-color: #F8F8F8;
  /*padding: 120px 0;*/ }
  .curse-container .offers .text .small {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase; }
  .curse-container .offers .box {
    margin-left: auto;
    max-width: 320px; }
    .curse-container .offers .box.archive {
      cursor: not-allowed; }
  .curse-container .offers .social {
    padding-top: 10px;
    padding-bottom: 10px; }
  .curse-container .offers .info {
    margin-top: 0; }
    .curse-container .offers .info div {
      padding-left: 40px; }
  .curse-container .offers .price {
    margin-top: 0;
    font-family: "Montserrat", sans-serif;
    padding: 20px 0; }
  .curse-container .offers .discount-badge {
    background-color: #e21d41;
    width: 70px;
    height: 70px;
    border-radius: 50%; }
    .curse-container .offers .discount-badge > div {
      transform: rotate(10deg);
      color: #ffffff;
      font-size: 30px;
      font-weight: bold; }
  .curse-container .offers .price-container {
    padding-left: 20px; }
    .curse-container .offers .price-container .pre {
      font-weight: 300;
      font-size: 14px; }
    .curse-container .offers .price-container .num {
      font-size: 60px;
      color: #e21d41;
      line-height: 1;
      font-weight: 700;
      font-family: "Montserrat", sans-serif; }

@media (max-width: 768px) {
  .curse-container .offers {
    margin-top: 100px; }
    .curse-container .offers .box {
      max-width: 100%; } }

@media (max-width: 576px) {
  .curse-container {
    padding: 30px 0; } }

@media (max-width: 575.98px) {
  .how-work {
    padding: 120px 0 90px 0; }
    .how-work .box {
      margin-bottom: 30px; }
  .company {
    text-align: center; }
    .company .text-1 {
      margin-top: 50px; }
    .company .text-2 {
      margin-bottom: 50px; }
  .check {
    display: none; }
  .offers {
    padding: 30px 0 !important; }
    .offers .box {
      margin-bottom: 30px !important; }
  .customers .testimonials .carousel-inner img {
    position: relative;
    left: initial;
    top: initial; }
  .customers .testimonials .carousel-item {
    padding: 0; }
  .customers .carousel-control-prev {
    left: 0 !important; }
  .customers .carousel-control-next {
    left: 50px; }
  .customers .testimonials .carousel-inner {
    padding: 15px 0; }
  .customers .testimonials .control {
    margin: 0; }
  .footer .who {
    padding: 0; }
  .footer .links {
    margin-bottom: 30px; } }

@media (max-width: 767.98px) {
  .how-work {
    padding: 120px 0 90px 0; }
    .how-work .box {
      margin-bottom: 30px; }
  .company {
    text-align: center; }
    .company .text-1 {
      margin-top: 50px; }
    .company .text-2 {
      margin-bottom: 50px; }
  .check {
    display: none; }
  .offers {
    padding: 0 0 90px 0; }
    .offers .box {
      margin-bottom: 30px !important; }
  .footer .who {
    margin-bottom: 30px;
    padding: 0; }
  .footer .links {
    margin-bottom: 30px; } }

@media (max-width: 991.98px) {
  .how-work .large-box .large-box-image {
    height: auto;
    width: 100%; }
  .navbar-brand {
    max-width: 161px;
    margin-right: auto; }
    .navbar-brand img {
      width: 100%; }
  .navbar > .container, .navbar > .container-fluid {
    justify-content: flex-end; }
  .navbar-dark .navbar-toggler {
    order: 2; }
  .navbar-dark .navbar-collapse {
    order: 3; }
  .navbar {
    background: #000000;
    padding: 20px; }
    .navbar .container {
      max-width: 100%; }
    .navbar .btn {
      margin-right: 20px; }
    .navbar .nav-item {
      padding: 0; }
    .navbar .navbar-nav {
      padding-top: 50px; }
  .slider .content {
    margin-bottom: 50px; }
  .how-work {
    padding: 120px 0 90px 0; }
    .how-work .box {
      margin-bottom: 30px; }
  .check {
    display: none; }
  .offers {
    padding: 30px 0; }
    .offers .box {
      margin-bottom: 30px !important; }
  .testimonials .carousel {
    width: 100%;
    margin-left: 0; }
  .customers .testimonials .carousel-item {
    padding-right: 0; }
  .footer .icon {
    margin-bottom: 10px; } }

@media (max-width: 576px) {
  .ready.ptb-120 {
    padding: 30px 0; }
  .footer.ptb-120 {
    padding-bottom: 30px; }
  .ptb-120 {
    padding-top: 30px; }
  .pb-120 {
    padding-bottom: 30px; }
  .f-30 {
    font-size: 20px;
    line-height: 1.2; }
  .f-46 {
    font-size: 33px; }
  .customers .testimonials .carousel-item p, .p-2, p {
    font-size: 18px; }
  .row {
    margin-left: -15px;
    margin-right: -15px; }
    .row > div {
      margin-bottom: 20px; }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto {
    padding-left: 15px;
    padding-right: 15px; }
  .how-work .large-box .content {
    padding: 30px; } }

@media (max-width: 499px) {
  .navbar .btn {
    margin-right: 10px; }
  .navbar-brand {
    max-width: 120px; } }
